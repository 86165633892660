<template>
  <div class="save-form-page">
    <h6 class="ym-title">
      <el-link class="ym-title-link" href="#/listFeeds">饲料信息 <i class="el-icon-arrow-right"></i> </el-link>
      <span>{{ title }}</span>
    </h6>
    <div class="ym-panel-general">
      <el-form :model="form" :rules="rules" ref="form" label-width="282px">
        <el-form-item v-if="this.id" class="is-required" prop="afcBatchFeedId" label="饲料批次编号：">
          <el-input v-model="form.afcBatchFeedId" placeholder="饲料批次编号自动生成" disabled size="medium"></el-input>
        </el-form-item>

        <el-form-item prop="productId" label="产品名称：">
          <select-page
            label="productName"
            value="productId"
            apiFile="currency"
            apiName="listProducts"
            placeholder="请选择产品名称"
            filter="productName"
            :selected.sync="productId"
            :params="productParams"
            @change="onProductChange"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="afcBatchId" label="产品批次编号：">
          <el-input
            v-if="id"
            v-model="form.batchNum"
            maxlength="50"
            disabled
            size="medium"
          ></el-input>
          <select-page
            v-else
            label="batchNum"
            value="afcBatchId"
            apiFile="currency"
            apiName="listAfcBatchs"
            placeholder="请选择产品批次编号"
            filter="batchNum"
            :selected.sync="afcBatchId"
            :params="batchParams"
            @change="onBatchChange"
            :disabled="isOpen"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="feedCompanyId" label="饲料厂商：">
          <select-page
            label="companyName"
            value="companyId"
            apiFile="currency"
            apiName="listCompanys"
            placeholder="请选择饲料厂商"
            filter="companyName"
            :selected.sync="companyId"
            :params="companyParams"
            @change="onCompanyChange"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="feedName" label="饲料名称：">
          <el-input
            placeholder="请输入饲料名称"
            v-model="form.feedName"
            maxlength="20"
            clearable
            size="medium"
          ></el-input>
        </el-form-item>

        <el-form-item prop="date" label="保质期：">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            size="medium"
            @blur="dateChange()"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="feedIntroduce" label="饲料介绍：">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="form.feedIntroduce"
            maxlength="50"
            show-word-limit
            size="medium"
            rows="3"
          >
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="goBack" size="medium" round plain>返回</el-button>
          <el-button type="primary" @click="submit" size="medium" round
            >确认提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '添加饲料信息',
      productId: '',
      afcBatchId: '',
      companyId: '',
      productParams: { enableFlag: 1 },
      batchParams: { inOutFlag: 2, enableFlag: 1, tacheTypeId: 2 },
      companyParams: {
        enableFlag: 1,
        companyTypeId: 103
      },
      date: '',
      isOpen: false, // 默认可选择产品批次编号
      // 表单对象
      form: {
        afcBatchFeedId: '', // 饲料批次编号
        productId: '', // 产品名称ID
        productName: '', // 产品名称
        afcBatchId: '', // 产品批次编号ID
        batchNum: '', // 产品批次编号
        feedCompanyId: '', // 饲料厂商ID
        feedCompanyName: '', // 饲料厂商
        feedName: '', // 饲料名称
        date: '', // 保质期
        feedBeginDate: '', // 保质期开始日期
        feedEndDate: '', // 保质期结束日期
        feedIntroduce: '' // 饲料介绍
      },
      rules: {
        productId: [
          { required: true, message: '请选择产品名称', trigger: 'change' }
        ],
        afcBatchId: [
          { required: true, message: '请选择产品批次编号', trigger: 'change' }
        ],
        feedCompanyId: [
          {
            required: true,
            message: '请选择入饲料厂商',
            trigger: 'change'
          }
        ],
        feedName: [
          {
            type: 'string',
            required: true,
            message: '请填写入饲料名称',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
          }
        ],
        date: [
          {
            required: true,
            message: '请选择起止时间',
            trigger: 'change'
          }
        ],
        feedIntroduce: [
          {
            type: 'string',
            required: true,
            message: '请填写饲料介绍',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  watch: {},
  created() {
    if (this.id) {
      this.title = '修改饲料信息'
      this.get()
      this.isOpen = true // 关闭产品批次选择
    }
  },
  methods: {

    goBack() {
      this.$router.push('/listFeeds')
    },
    // 选择产品名称
    onProductChange(val) {
      if (!val) {
        this.form.productId = ''
        this.form.productName = ''
      } else {
        this.form.productId = val.productId
        this.form.productName = val.productName
      }
    },

    // 选择产品批次编号
    onBatchChange(val) {
      if (!val) {
        this.form.afcBatchId = ''
        this.form.batchNum = ''
      } else {
        this.form.afcBatchId = val.afcBatchId
        this.form.batchNum = val.batchNum
      }
    },
    // 选择饲料厂商
    onCompanyChange(val) {
      if (!val) {
        this.form.feedCompanyId = ''
        this.form.feedCompanyName = ''
      } else {
        this.form.feedCompanyId = val.companyId
        this.form.feedCompanyName = val.companyName
      }
    },

    // 选择日期
    dateChange() {
      this.form.feedBeginDate = this.date[0]
      this.form.feedEndDate = this.date[1]
      this.form.date = this.date
      // this.form.date = date
      console.log(this.form.date)
    },

    // 提交表单
    submit() {
      this.$refs['form'].validate(async(valid) => {
        if (!valid) return
        else {
          if (this.id) {
            this.edit()
          } else {
            this.add()
          }
        }
      })
    },

    // 查询
    async get() {
      const params = {
        afcBatchFeedId: this.id
      }
      const { data } = await this.$http.feedInfor.getFeed(params)
      if (data.code !== '0') return this.$message.error(data.msg || '查询失败')
      // 回显表单
      console.log('查询数据：', data.data)
      this.form = data.data
      this.companyId = data.data.feedCompanyId
      this.afcBatchId = data.data.afcBatchId
      this.productId = data.data.productId
      const date = []
      date[0] = new Date(data.data.feedBeginDate)
      date[1] = new Date(data.data.feedEndDate)
      this.form.date = date
      this.date = date
      console.log('保质期：', this.form.date)
    },
    // 添加
    async add() {
      const params = { ...this.form }
      const { data } = await this.$http.feedInfor.saveFeed(params)
      if (data.code !== '0') return this.$message.error(data.msg || '新增失败')
      this.$message.success('新增成功')
      this.goBack()
    },
    // 修改
    async edit() {
      const params = { ...this.form }
      const { data } = await this.$http.feedInfor.updateFeed(params)
      if (data.code !== '0') return this.$message.error(data.msg || '修改失败')
      this.$message.success('修改成功')
      this.goBack()
    }
  }
}
</script>

<style lang="scss" scrope>
.save-form-page {
  .el-form {
    margin-top: 40px;
  }
  .warning {
    color: #e6a23c !important;
  }

  .el-input {
    width: 400px;
  }

  .el-textarea {
    width: 400px;
  }

  .upload-demo {
    width: 400px;
  }

  .el-range-editor--medium.el-input__inner {
    width: 400px;
}
}
</style>
